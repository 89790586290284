<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import type { AssistantColor } from '@/types/assistant';

interface Props {
  assistantType?: AssistantColor;
  size: 'sm' | 'md' | 'lg';
  customClasses?: string;
  typeStatus?: 'started' | 'finished' | null;
}

const props = withDefaults(defineProps<Props>(), {
  assistantType: 'SAPPHIRE',
  typeStatus: null,
  customClasses: '',
});

const imageSrc = computed(() => {
  return props.size === 'lg' ? '/images/ai-blue-lg.webp' : '/images/ai-blue.webp';
});

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('coach-img pointer-events-none block aspect-square scale-90', {
      variants: {
        size: {
          sm: 'h-10 w-10',
          md: 'h-16 w-16',
          lg: 'h-40 w-40',
        },
      },
    })({
      size: props.size,
    }),
    props.customClasses,
  );
});

const { $gsap } = useNuxtApp();
const animation = ref<GSAPTimeline | GSAPTween | null>(null);

const startAnimation = () => {
  // Start pulsing and rotating animation
  animation.value = $gsap
    .timeline({ repeat: -1 })
    .to('.coach-img', { scale: 1, duration: 1, ease: 'power1.inOut', yoyo: true, repeat: -1 }) // Pulsing effect
    .to('.coach-img', { rotation: 360, duration: 8, ease: 'none', repeat: -1 }, 0); // Continuous rotation
};

const stopPulsing = () => {
  // Stop pulsing, but keep rotating
  if (animation.value) {
    animation.value.kill(); // Stop all tweens in the timeline
    animation.value = $gsap.to('.coach-img', { rotation: 360, duration: 8, ease: 'none', repeat: -1 });
  }
};

watch(
  () => props.typeStatus,
  (newStatus) => {
    if (newStatus === 'started') {
      startAnimation();
    } else if (newStatus === 'finished') {
      setTimeout(() => {
        stopPulsing();
      }, 1000);
    }
  },
);
</script>

<template>
  <NuxtImg :class="variantClasses" :src="imageSrc" alt="" role="presentation" />
</template>
